<template>
  <div>
    <div v-if="!errorShow" class="common-form-layout">
        <!-- 标题-添加巡课记录 -->
        <global-page-back
            :detailPageTitle="title"
            @handlerGlobalType="handleBack"
        >
        </global-page-back>
        <div class="common-form-wrapper content-bg">
            <div class="inspect-content">
                <div class="based-content" id="content">
                <div class="based-title">
                    <span>基础信息</span>
                    <div class="based-line"></div>
                </div>
                <div class="based-main">
                    <div class="based-form">
                        <el-row :gutter="20">
                            <el-col :span="2" class="based-sub-title">&nbsp;&nbsp;基础设置</el-col>
                            <el-col :span="7" style="display: flex">
                                <span class="msg-title">方案名称:</span>
                                <span class="msg-content"><text-tooltip :content="basedFormData.programmeName" placement="bottom"></text-tooltip></span>
                            </el-col>
                            <el-col :span="7" style="display: flex">
                                <span class="msg-title">巡课教师:</span>
                                <span class="msg-content"><text-tooltip :content="basedFormData.classTeacherName" placement="bottom"></text-tooltip></span>
                            </el-col>
                            <el-col :span="7" style="display: flex">
                                <span class="msg-title">巡课方式:</span>
                                <span class="msg-content">{{ basedFormData.programmeType == '1'? '自由巡课' : '固定课程巡课' }}</span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="2">
                                <span style="visibility:hidden">占位符号:</span>
                            </el-col>
                            <!-- 自由巡课 -->
                            <div v-if="basedFormData.programmeType == '1'">
                                <el-col :span="7" style="display: flex">
                                    <span class="msg-title">巡课对象:</span>
                                    <span class="msg-content"><text-tooltip :content="basedFormData.targetName" placement="bottom"></text-tooltip></span>
                                </el-col>
                                <el-col  :span="7" style="display: flex">
                                    <span class="msg-title">选择日期:</span>
                                    <el-tooltip class="item" effect="dark" :content="basedFormData.classTime" :disabled="(basedFormData.classTime&&basedFormData.classTime.length>23) ? false : true">
                                        <span class="msg-content">{{ basedFormData.classTime || '-' }}</span>
                                    </el-tooltip>
                                </el-col>
                            </div>
                            <!-- 固定巡课 -->
                            <div v-if="basedFormData.programmeType == '2'">
                                <el-col :span="21" style="display: flex">
                                    <span class="msg-title">巡课对象:</span>
                                    <span class="msg-content3" style="width: 890px"><text-tooltip :content="basedFormData.targetName" placement="bottom"></text-tooltip></span>
                                </el-col>
                            </div>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="2" class="based-sub-title">&nbsp;&nbsp;评价类型</el-col>
                            <el-col :span="7" style="display: flex">
                                <span class="msg-title">评价类型:</span>
                                <span class="msg-content">{{ basedFormData.assessType || '-' }}</span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="2" class="based-sub-title">&nbsp;&nbsp;消息推送</el-col>
                            <el-col :span="21" class="msgDiv">
                                <span class="msg-title">推送类型:</span>
                                <div v-if="dataList.isPermTeacher == '1'" class="msg-content" style="width: 224px">任教班级、负责班级、班级权限教师</div>
                                <div>{{ (dataList.isPermTeacher == '1' && (dataList.isClassTeacher == '1' || dataList.isPost == '1' || dataList.isCustom == '1')) ? '；' : '' }}</div>
                                <div v-if="dataList.isClassTeacher == '1'" class="msg-content2">上课教师</div>
                                <div>{{ (dataList.isClassTeacher == '1' && (dataList.isPost == '1' || dataList.isCustom == '1')) ? '；' : '' }}</div>
                                <div v-if="dataList.isPost == '1'" style="display: flex">
                                    <span class="msg-content2">按职务推送</span>
                                    <el-tooltip class="item" effect="dark" :content="isPostArr.toString()" :disabled="(isPostArr.toString()&&isPostArr.toString().length>10) ? false : true">
                                        <span class="msg-content2">（{{ isPostArr.toString() }}）</span>
                                    </el-tooltip>
                                </div>
                                <div>{{ (dataList.isPost == '1' && dataList.isCustom == '1') ? '；' : '' }}</div>
                                <div v-if="dataList.isCustom == '1'" style="display: flex">
                                    <span class="msg-content2">自定义推送人</span>
                                    <el-tooltip class="item" effect="dark" :content="isCustomArr.toString()" :disabled="(isCustomArr.toString()&&isCustomArr.toString().length>10) ? false : true">
                                        <span class="msg-content">（{{ isCustomArr.toString() }}）</span>
                                    </el-tooltip>
                                </div>
                                <div v-if="dataList.isPermTeacher != '1' && dataList.isClassTeacher != '1' && dataList.isPost != '1' && dataList.isCustom != '1'">-</div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="based-title">
                    <span>方案设计</span>
                    <div class="based-line"></div>
                </div>
                <div class="detail-main">
                    <div v-if="!contentList.hasOwnProperty('1') && !contentList.hasOwnProperty('2') && !contentList.hasOwnProperty('3')" class="empty-div">
                        <img :src="emptyImg" alt="">
                        <span class="empty-info">暂无内容</span>
                    </div>
                    <div v-else class="behavior-div">
                        <!-- 教师行为 -->
                        <div v-if="contentList.hasOwnProperty('1')">
                            <div class="behavior-title">
                                <span class="title-line"></span>
                                <span class="title-info">教师行为</span>
                            </div>
                            <div v-for="(item,index) in keyArrTeacher" :key="index" class="behavior-content">
                                <div class="behavior-content-title">{{index+1}} 、 {{item.contentName}}</div>
                                <div style="width: 100%">
                                    <div class="option-div" v-if="item.type == '1'">
                                        <el-radio-group readonly v-for="(subItem,idx) in item.list" :key="idx" class="radio-div">
                                            <el-tooltip class="item" effect="dark" :content="subItem.setName" :disabled="(subItem.setName&&subItem.setName.length>26) ? false : true">
                                                <el-radio :label="subItem.setId" style="display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                    <span>{{ subItem.setName }} </span>
                                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                                </el-radio>
                                            </el-tooltip>
                                        </el-radio-group>
                                    </div>
                                    <div class="option-div" v-else-if="item.type == '2'">
                                        <el-checkbox-group v-model="arrTeacher" v-for="(subItem,idx) in item.list" :key="idx" class="radio-div" @change="changeCheckBoxTeacher()">
                                            <el-tooltip class="item" effect="dark" :content="subItem.setName" :disabled="(subItem.setName&&subItem.setName.length>26) ? false : true">
                                                <el-checkbox class="behavior-item-checkbox" :label="subItem.setId">
                                                    <span>{{ subItem.setName }} </span>
                                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                                </el-checkbox>
                                            </el-tooltip>
                                        </el-checkbox-group>
                                    </div>
                                    <div v-else-if="item.type == '3'" class="remark">
                                        <div v-show="item.list[0].lowScore != null">
                                            <el-input-number controls-position="right" :min="item.list[0].lowScore" :max="item.list[0].highScore"></el-input-number>
                                            <span class="remark-info">({{item.list[0].lowScore }} - {{ item.list[0].highScore }} 分)</span>
                                        </div>
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="请输入内容"
                                            maxlength="100"
                                            show-word-limit
                                            readonly
                                            style="width: 920px;margin-top:5px"
                                        >
                                        </el-input>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- 学生行为 -->
                        <div v-if="contentList.hasOwnProperty('2')" class="behavior-line"></div>
                        <div v-if="contentList.hasOwnProperty('2')">
                            <div class="behavior-title">
                                <span class="title-line"></span>
                                <span class="title-info">学生行为</span>
                            </div>
                            <div v-for="(item,index) in keyArrStu" :key="index" class="behavior-content">
                                <div class="behavior-content-title">{{index+1}} . {{item.contentName}}</div>
                                <div style="width: 100%">
                                    <div class="option-div" v-if="item.type == '1'">
                                        <el-radio-group readonly v-for="(subItem,idx) in item.list" :key="idx" class="radio-div">
                                            <el-tooltip class="item" effect="dark" :content="subItem.setName" :disabled="(subItem.setName&&subItem.setName.length>24) ? false : true">
                                                <el-radio :label="subItem.setId" style="display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                    <span>{{ subItem.setName }}</span>
                                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                                </el-radio>
                                            </el-tooltip>
                                        </el-radio-group>
                                    </div>
                                    <div class="option-div" v-else-if="item.type == '2'">
                                        <el-checkbox-group v-model="arrStudent" v-for="(subItem,idx) in item.list" :key="idx" class="radio-div" @change="changeCheckBoxStudengt()">
                                            <el-tooltip class="item" effect="dark" :content="subItem.setName" :disabled="(subItem.setName&&subItem.setName.length>24) ? false : true">
                                                <el-checkbox class="behavior-item-checkbox" :label="subItem.setId">
                                                    <span>{{ subItem.setName }}</span>
                                                    <span v-show="subItem.setScore || subItem.setScore==0">({{ subItem.setScore > 0 ? `+` + subItem.setScore : subItem.setScore }}分)</span>
                                                </el-checkbox>
                                            </el-tooltip>
                                        </el-checkbox-group>
                                    </div>
                                    <div v-else-if="item.type == '3'" class="remark">
                                        <span v-show="item.list[0].lowScore != null">
                                            <el-input-number controls-position="right" :min="item.list[0].lowScore" :max="item.list[0].highScore"></el-input-number>
                                            <span class="remark-info">({{ item.list[0].lowScore }} - {{ item.list[0].highScore }} 分)</span>
                                        </span>
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="请输入内容"
                                            maxlength="100"
                                            readonly
                                            show-word-limit
                                            style="width: 920px;margin-top:5px;"
                                        >
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 备注说明 -->
                        <div v-if="contentList.hasOwnProperty('3')" class="behavior-line"></div>
                        <div v-if="contentList.hasOwnProperty('3')">
                            <div class="behavior-content">
                                <div class="behavior-title">
                                    <span class="title-line"></span>
                                    <span class="title-info">备注说明</span>
                                </div>
                                <div>
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        placeholder="请输入内容"
                                        maxlength="500"
                                        show-word-limit
                                        readonly
                                        style="width: 920px;margin-top: 10px;margin-left: 23px">
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <error v-else :errorShow="errorShow"></error>
  </div>
</template>

<script>
import { Error, debounce } from 'common-local';
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import TextTooltip from "@/components/scrollWrapper/Sub/TableData/TextTooltip.vue";
import { mapState } from 'vuex';
export default {
    name:'AddObservationRecordsView',
    props:{
        detailId: {
            type: String
        }
    },
    components: {
        Error,
        GlobalPageBack,
        TextTooltip
    },
    data(){
        return{
            arrTeacher:[],
            arrStudent:[],
            radioDisabled:false,
            title:'方案详情',
            errorShow: false,
            value1:'',
            basedFormData:{
                schoolId:'',
                programmeId:'',//方案id
                programmeName:'',//方案名称
                programmeType:'',//方案类型
                targetId:'',//巡课对象id
                targetName:'',//巡课对象名称
                classId:'',//巡课班级id
                className:'',//巡课班级名称
                classTeacherId:'',//上课教师id
                classTeacherName:'',//上课教师名称
                classTime:'',//上课时间
                subjectId:'',//巡课科目id
                subjectName:'',//巡课科目名称
                gradeId:'',//年级id
                gradeName:'',//年级名称
                infoList:[],
                assessType: '',
                pushType: ''
            },
            patrolTargetForm:{},
            contentList:[], //详情列表
            keyArrTeacher:[],//教师详情问题Id数组
            keyArrStu:[],//学生详情问题Id数组
            radio:'',
            gradeList:[],
            gradeObj:{   //自由巡课对象班级下拉列表
                key: 'classIds',
                value: [],
                organType: "",
                list: [],
                cascaderProps: {
                    label: "name",
                    multiple: false,
                    value: "id",
                    emitPath: false
                },
                filter(data) {
                    return data.organType !== '1';
                },
            },
            list:{},
            dataList:{},
            isPostArr:'',//按职务推送人员
            isCustomArr:'',//自定义推送人员

        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        }),
        emptyImg(){
            return require('@/assets/images/empty-patrol.png')
        }
    },
    created(){
        this.getDetailsById()
    },
    mounted() {
        this.$nextTick(() => {
            document.getElementById('content').style.height = document.body.clientHeight - document.getElementById('content').offsetTop - 76 + 'px'
            document.getElementById('content').style.overflowY = 'auto'
        })
        window.onresize = debounce(() => {
            document.getElementById('content').style.height = document.body.clientHeight - document.getElementById('content').offsetTop - 76 + 'px'
            document.getElementById('content').style.overflowY = 'auto'
        }, 200)
    },
    methods:{
        changeCheckBoxTeacher(){
            this.arrTeacher = []
        },
        changeCheckBoxStudengt(){
            this.arrStudent = []
        },
        radioMouseMove(){
            const el = document.getElementsByClassName('')
        },
        /**
         * @Description: 返回上一页
         * @Author: mrz
         * @Date: 2024-08-23 14:39:36
         */
        handleBack() {
            this.$emit('switchCom', {com: 'List'})
        },
        /**
         * @Description: 获取记录详情
         * @Author: mrz
         * @Date: 2024-08-27 19:18:34
         */
        getDetailsById(){
            this._fet('/school/schoolPatrolProgramme/getDetailById', {id: this.detailId}).then(res => {
                console.log('巡课方案详情：',res)
                if(res.data.code == 200){
                    this.dataList = res.data.data
                    let data = res.data.data
                    this.title = data.name + '方案详情'
                    let arr = []
                    data.patrolTeacher.forEach(item => {
                        arr.push(item.name)
                    })
                    let targetArr = []
                    data.target.forEach(val => {
                        targetArr.push(val.name)
                    })
                    // targetType 巡课对象类型1班级2教师
                    // type 巡课方式1自由巡课2固定巡课
                    if(data.type == 1){
                        // 自由巡课
                        this.basedFormData.targetName = targetArr.toString() || '-'
                    }else{
                        // 固定巡课
                        this.getTargetByProId(data.id)
                    }

                    // 消息推送
                    if(data.isPost == '1'){
                        this.isPostArr = []
                        data.postScope.forEach(a => {
                            this.isPostArr.push(a.name)
                        })
                    }
                    if(data.isCustom == '1'){
                        this.isCustomArr = []
                        data.customScope.forEach(b => {
                            this.isCustomArr.push(b.name)
                        })
                    }
                    this.basedFormData.programmeName = data.name || '-'
                    this.basedFormData.classTeacherName = arr.toString() || '-'
                    this.basedFormData.programmeType = data.type
                    if (data.type == '1' && data.startTime) {
                        this.basedFormData.classTime = data.startTime.split(' ')[0] + ' - ' + res.data.data.endTime.split(' ')[0]
                    }

                    let assessTypeList = //评价类型1教师行为2学生行为3备注说明
                    {
                        '1': '教师行为',
                        '2': '学生行为',
                        '3':'备注说明'
                    }

                    this.basedFormData.assessType =  data.assessType.split(',').map(item => {
                        return assessTypeList[item]
                    }).toString()
                    // 详情数据处理---教师信息
                    this.contentList = data.map
                    const keyArrTeacherId =  this.contentList.hasOwnProperty('1') ? Object.keys(this.contentList[1]) : []
                    keyArrTeacherId.forEach((item, index) => {
                        this.keyArrTeacher.push({
                            contentId: this.contentList[1][item][0].contentId,
                            contentName: this.contentList[1][item][0].contentName,
                            type: this.contentList[1][item][0].type,
                            conSort: this.contentList[1][item][0].conSort,
                            list: []
                        })
                        this.contentList[1][item].forEach(i => {
                            this.keyArrTeacher[index].list.push({
                                setId: i.setId,
                                setName: i.setName,
                                setSort: i.setSort,
                                setScore: i.setScore,
                                groupType: i.groupType,
                                lowScore: i.lowScore,
                                highScore: i.highScore,
                            })
                        })
                    })
                    this.keyArrTeacher = this.keyArrTeacher.sort((a, b) => a.conSort - b.conSort)
                    console.log('教师的顺序', this.keyArrTeacher)
                    // 学生信息
                    const keyArrStuId =  this.contentList.hasOwnProperty('2') ? Object.keys(this.contentList[2]) : []
                    keyArrStuId.forEach((item, index) => {
                        this.keyArrStu.push({
                            contentId: this.contentList[2][item][0].contentId,
                            contentName: this.contentList[2][item][0].contentName,
                            type: this.contentList[2][item][0].type,
                            conSort: this.contentList[2][item][0].conSort,
                            list: []
                        })
                        this.contentList[2][item].forEach(i => {
                            this.keyArrStu[index].list.push({
                                setId: i.setId,
                                setName: i.setName,
                                setSort: i.setSort,
                                setScore: i.setScore,
                                groupType: i.groupType,
                                lowScore: i.lowScore,
                                highScore: i.highScore,
                            })
                        })
                    })
                    this.keyArrStu = this.keyArrStu.sort((a, b) => a.conSort - b.conSort)
                    console.log('教师的顺序', this.keyArrStu)
                }else if(res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 如果是固定巡课，获取巡课对象
         * @Author: pyl
         * @Date: 2024-08-29 19:07:24
         */
        getTargetByProId(id) {
            this._fet('/school/schoolPatrolProgramme/getTargetByProId', {id}).then(res => {
                if(res.data.code == 200) {
                    let tourObject = ''
                    res.data.data.sort((a, b) => {
                        if (a.scheduleTime == b.scheduleTime) {
                            return a.restTimeStartTime.localeCompare(b.restTimeStartTime)
                        } else {
                            return a.scheduleTime.localeCompare(b.scheduleTime)
                        }
                    })
                    res.data.data.forEach((i, j) => {
                        if (j < res.data.data.length - 1) {
                            tourObject = tourObject + i.teacherName + ' (' + i.scheduleGradeName +  '/' +i.className + ')' + ' ' + this.handleDate(i.scheduleTime,i.restTimeStartTime,i.restTimeEndTime) + ', '
                        } else {
                            tourObject = tourObject + i.teacherName + ' (' + i.scheduleGradeName +  '/' + i.className + ')' + ' ' + this.handleDate(i.scheduleTime,i.restTimeStartTime,i.restTimeEndTime)
                        }
                    })
                    this.basedFormData.targetName = tourObject || '-'
                } else if(res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description:处理日期
         * @Author: mrz
         * @Date: 2024-08-26 10:25:53
         */
         handleDate(val,a,b){
            const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const date = new Date(val)
            return days[date.getDay()]  + ' (' + val + ' ' + a.substring(0,5) + '-' + b.substring(0,5) + ')'

         },
    }
}
</script>

<style lang="scss" scoped>
.global-page-back {
    position: relative;
    top: -38px;
    z-index: 1;
    box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.1);
}
.common-form-wrapper{
    display: flex;
    justify-content: center;
    margin-top: -38px;
}
.inspect-content{
    margin-top: 10px;
    width: 1130px;
    overflow-x: auto;
    background-color: #fff;
}
.based-content{
    width: 100%;
    overflow-y: auto;
    .based-title{
        box-sizing: border-box;
        padding: 16px 0 12px 19px;
        border-bottom: 1px solid #EDEFF2;
        position: relative;
        .based-line{
            position: absolute;
            left: 23px;
            bottom: -1px;
            width: 56px;
            height: 3px;
            background: #3C7FFF;
            border-radius: 2px;
        }
        span{
            display: flex;
            justify-content: flex-start;
            height: 16px;
            line-height: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #2B2F33;
        }
    }
    .based-main{
        padding: 6px 10px;
        .based-form{
            box-sizing: border-box;
            background: #F5F9FD;
            border-radius: 4px;
            padding:10px;
            .msg-title{
                display: inline-block;
                min-width: 70px;
                text-align: left;
            }
            .msg-content{
                display: inline-block;
                width: 180px;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .msg-content2{
                display: inline-block;
                max-width: 180px;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
            }
            .msg-content3{
                width: 890px;
                display: inline-block;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
            }
            .msg-content-fixed{
                display: inline-block;
                max-width: 700px;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
            }
        }
        ::v-deep .el-row{
            height: 42px;
        }
        ::v-deep .el-col{
            text-align: left
        }
    }
    .detail-main{
        .empty-div{
            height: calc(100vh - 431px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .empty-info{
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
            }
        }
        .behavior-div{
        margin-bottom: 30px;
            padding:0 22px;
            box-sizing: border-box;
            .behavior-line{
                height: 0;
                width: 100%;
                border-bottom: 1px dashed #E1E3E6;
            }
            .behavior-title{
                height: 48px;
                padding: 16px 0;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .title-line{
                    display: inline-block;
                    width: 4px;
                    height: 15px;
                    background: #3C7FFF;
                    border-radius: 1px;
                    margin-right: 7px;
                }
                .title-info{
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 16px;
                    color: #2B2F33;
                }
            }
            .behavior-content{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 30px;
            .behavior-content-title{
                margin-bottom: 5px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #2B2F33;
            }
            }
        }
    }
}
.option-div{
    margin-left: 23px;
    display: flex;
    flex-wrap: wrap;
    .radio-div{
        text-align: left;
        margin: 10px 38px 10px 0;
        width: 45%;
        min-width: 444px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    .behavior-item-checkbox{
        display: flex;
        margin-right: 38px;
        align-items: center;
    }
}
.option-div ::v-deep .el-checkbox__label{
    display: block;
    width: 444px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.remark{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 23px;
    .remark-info{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-left: 16px;
    }
}

.patrol-target-search{
    ::v-deep .el-input--small .el-input__inner{
        background-color: #F5F5F5;
    }
    .patrol-target-line{
        width: 100%;
        height: 0;
        border-bottom: 1px dashed #D8D8D8;
    }
}
.patrol-target-timetable{
    margin-top: 23px;
}
::v-deep .el-dialog__body{
    margin: 0 !important;
    padding: 17px 40px 0px 31px !important;
}
::v-deep .el-cascader__tags{
    left: 6px;
}
::v-deep .el-cascader-menu{
    min-width: 140px;
}
.active{
span{
    color:#3C7FFF !important
}
}
.based-sub-title{
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 14px;
    color: #333333;
}
.msgDiv{
    display: flex;
}
.msg-title{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #888888;
}
.msg-content{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}
.based-content .based-main[data-v-8c371f12] .el-col{
    display: flex;
    align-items: center;
}
</style>
